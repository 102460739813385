import React, { FC } from "react";
import { Pdf } from "../../../models/applications/applicationInterfaces";
import "./taskViewer.scss";
import api from "../../../utils/api";
import { useDispatch } from "react-redux";
import { systemMessageSlice } from "../../../models/systemMessageSlice";
import { articleTypeToIcon } from "../../../utils/helpers";

interface Props {
  pdfs: Pdf[];
}
const PdfViewer: FC<Props> = ({ pdfs }) => {
  const dispatch = useDispatch();

  async function downloadDocument(uuid: string) {
    try {
      const res: any = await api.post(
        `/onboarding-applications/pdfs/${uuid.toString()}/signed-url`
      );
      window.open(res.data.url, "_blank", "noopener, noreferrer");
    } catch (e) {
      dispatch(
        systemMessageSlice.actions.setMessage({
          message: "Error - Unable to access document. Please try again.",
          type: "danger",
        })
      );
    }
  }

  if (!pdfs.length) {
    return null;
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-12 mb-4">
          <div className="d-flex align-items-center">
            {/* Completion Icon */}
            <div className={"task-count complete"}>
              <span className="oi oi-check"></span>
            </div>
            {/* Title */}
            <p className="text-tertiary h2 mb-0" style={{ fontWeight: 750 }}>
              Printable Forms
            </p>
          </div>
        </div>
        {pdfs.map((pdf) => (
          <>
            {/* File and Description Block */}
            <div className="col-sm-12 col-md-4">
              <div key={pdf.uuid} className="mb-1">
                <div className="file-container">
                  <div className="dropdown">
                    <div
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="d-flex"
                      style={{ userSelect: "none" }}
                    >
                      <img src={articleTypeToIcon["PDF"]} className="my-auto" />
                      <div
                        className="col"
                        style={{
                          overflowX: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <span>{pdf.s3Key.split("/").pop()}</span>
                      </div>
                      <ion-icon
                        name="ellipsis-vertical"
                        style={{ height: "auto" }}
                      />
                    </div>
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="dropdownMenu2"
                    >
                      <a
                        className="dropdown-item"
                        href="javascript:void(0);"
                        onClick={() => downloadDocument(pdf.uuid)}
                      >
                        Download
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Task Description */}
            <div className="col-sm-12 col-md-8 mt-3 mt-sm-0">
              <p className="font-weight-bold h5 mb-0 text-textGray">
                {pdf.description}
              </p>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default PdfViewer;
